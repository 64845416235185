body {
    font-size: 16px;
font-family: "Questrial", sans-serif;
    color: #f5f5f5;
    text-align: center;
}
html {
background: rgb(28,12,12);
background: linear-gradient(0deg, rgba(28,12,12,1) 0%, rgba(15,14,14,1) 100%);
min-height: 100vh;
}

a {
    color: inherit;
    text-decoration: inherit;
}

p {
    padding: 0;
    margin: 0;
}