.header {
    width: 100%;
    background-color: rgba(255,255,255, 0.02);
    margin: 0;
    padding: 20px 10px;

    ul {
        list-style: none;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 0;
        li {
            font-size: 18px;
            cursor: pointer;
            width: fit-content;
            padding: 10px 15px;
            border-radius: 20px;
            transition: 0.5s all ease;
            font-weight: bold;
            border: 2px solid transparent;
            margin: 0 5px;
            &:hover {
                background-color: rgba(255,255,255, 0.1);
                filter: opacity(1) !important;
            }
            &.active {
                font-size: 24px;
                color: crimson;
                text-shadow: 0 0 5px crimson;
                letter-spacing: 1px;

                &:hover {
                    background-color: rgba(crimson, 0.1);
                    border: 2px solid crimson;
                }
            }
            &.disabled, &.inactive {
                filter: opacity(0.5);
            }

            &.disabled {
                cursor: not-allowed;
            }
            @media (max-width: 768px){
                width: 100%;
                font-size: 14px !important;
            }
        }
        a {
             @media (max-width: 768px){
                width: 100%;
            }
        }
    }
}