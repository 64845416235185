.leaderboard-banner {
    h1 {
        width: 100%;

        @media (max-width: 768px) {
            font-size: 24px;
            
        }
    }

    .promotion {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        span{
            font-size: 24px;
            @media (max-width: 768px) {
                font-size: 18px;
            }

            &.code {
                margin: 0 10px;
                color: crimson;
                font-weight: bold;
                font-size: 28px;
                @media (max-width: 768px) {
                    font-size: 22px;
                }
                &::before {
                    content: '"'
                }
                &::after {
                    content: '"';
                }
            }

            img {
                width: 150px;
                margin-left: 10px;
                margin-top: 10px;
            }
        }
    }

    #leaderboard-banner-title-img{
        img {
            width: 40vw;
            @media (max-width: 768px) {
                width: 50%;
            }
        }
    }
}