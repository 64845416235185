.calculator {
    padding: 50px;
    h3 {
        margin-bottom: 50px;
    }
    .input-container {
        display: flex;
        width: 100%;
        justify-content: center;
        padding: 20px;
        flex-wrap: wrap;
        margin: 0 auto;
        div {
            position: relative;
            margin: 0 30px;
            flex: 1 1;
            min-height: 100px;
            min-width: 200px;
            max-width: 400px;
            p {
                position: absolute;
                font-size: 16px;
                top: -40px;
                color: crimson;
            }
            input {
                outline: none;
                background: transparent;
                border: 2px solid rgba(white, 0.5);
                border-radius: 5px;
                padding: 5px;
                color: crimson;
                font-size: 22px;
                width: 100%;
                &:hover,&:focus,&:active {
                    border: 2px solid crimson;
                }
            }
            select {
                background-color: transparent;
                font-size: 16px;
                color: crimson;
                padding: 3px;
                font-size: 22px;
                width: 100%;
            }

            option {
                background-color: rgba(black, 0.9);
                
                &.grey {
                    color: white
                };
                &.gold {
                    color: gold;
                }
                &.green {
                    color: lime
                }
                &.red {
                    color: crimson;
                }
            }
        }
    }

    .details {
        .highlight {
            color: crimson;
        }
        p {
            font-size: 22px;
            img {
                width: 36px;
                margin-left: -10px;
                margin-bottom: -2px;
            }
        }
    }
}

.dice-strategy {
    margin: 50px 0;
    padding: 0 20px;
    img {
        width: 90%;

        @media (min-width: 768px){
            width: 50%;
        }
    }
    .highlight {
        color: crimson;
        img {
            width: 36px;
            margin-left: -10px;
            margin-bottom: -2px;
        }
    }
    ol {
        width: fit-content;
        margin: 0 auto;
        text-align: left;
        li {
            font-size: 20px;
            color: crimson;
            margin: 10px 0;
        }
    }
}