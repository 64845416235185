.results {
    width: 100%;
    margin: 30px auto;
    .container {
        display: flex;
        margin: 0 auto;
        width: fit-content;
        justify-content: center;
        border: 2px solid rgba(white, 0.3);
        border-radius: 5px;
        *{
            span {
                display: block;
                height: 40px;
                padding: 6px 25px;
                font-size: 22px;
                &:nth-child(odd){
                    background-color: rgba(white, 0.05);
                }

                @media (max-width: 768px) {
                    font-size: 14px;
                    height: 30px;
                    padding: 4px 10px;
                }

                @media (max-width: 500px) {
                    font-size: 12px;
                    height: 18px;
                    padding: 2px 5px;
                }
            }
        }
    }
}