.rewards{
    width: 100%;
    margin-top: 50px;
    .total-rewards{
        position: relative;
        min-height: 150px;
        width: fit-content;
        margin: 0 auto;
        font-size: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: gold;
        font-weight: bold;
        .csgoroll-coin{
            transform: scale(2.5) translateY(-4px);
        }
        .background-image{
            position: absolute;
            z-index: -1;
            width: 350px;
            height: 150px;
        }

        // &::after{
        //     content: "";
        //     background-image: url('https://www.csgoroll.com/assets/seasonal-branding/christmas/snow-top-right-small.webp');
        //     width: 500px;
        //     height: 100px;
        //     background-size: cover;
        //     position: absolute;
        //     transform: scale(0.5);
        //     top: -42px;
        //     right: -155px;
        // }
    }
    .prizes{
        width: 80%;
        display: flex;
        margin: 0 auto;
        flex-wrap: wrap;
        justify-content: center;
        .prize{
            border-radius: 10px;
            border: 2px solid crimson;
            padding: 2px 5px 20px;
            width: 100%;
            flex: 1 1;
            margin: 10px;
            cursor: pointer;
            background-color: #15181c;
            box-shadow:inset 0 0 10px crimson;
            position: relative;
            .position {
                font-size: 22px;
                margin-bottom: 10px;
            }
            transition: 0.1s;
            &:hover {
                scale: 1.05;
            }

            @media (max-width: 768px) {
                width: 50%;
                flex: initial;
            }

            .lastMonthAmount {
                transform: translateY(10px);
                font-style: italic;
                opacity: 0.8;
            }

            .amount {
                font-size: 26px;
                transform: scale(1.2) translateX(8px);
                display: inline-block;
                img {
                    margin-left: -4px;
                }
            }

            .decoration{
                position: absolute;
                z-index: 10;
                top: -125px;
                right: -75px;
                transform: scale(0.4) rotate(12.5deg);
                img {
                    width: 250px;
                    display: block;
                }
                @media (max-width: 768px) {
                    top: -100px;
                    right: -100px;
                    transform: scale(0.35) rotate(12.5deg);
                    img {
                        width: 200px;
                    }
                }
            }
        }
    }

    .title {
        font-weight: bold;
        font-size: 28px;
        text-shadow: 0 0 25px rgba(gold, 0.5);
        letter-spacing: 1px;
    }

    .explanation {
        width: fit-content;
        text-align: center;
        margin: 0 auto;
        h4 {
            font-style: italic;
        }
        ol {
            text-align: left;
            font-size: 18px;

            .code {
                font-size: 20px;
                font-weight: bold;
                color: crimson;
            }
        }
    }
}